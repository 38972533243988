import Head from "next/head";
import { useRouter } from "next/router";
import React, { FC, useEffect } from "react";

// @ts-ignore
import Logger from "shared/services/Logger";

import Img from "@components/FileServer/Img";

import { PAGE_NOT_FOUND_IMAGE } from "./assets";

interface IDefaultErrorPage {
  err?: Error;
  className: string;
  texts: {
    pageTitle: string;
    heading: string;
    description?: string;
  };
}

const DefaultErrorPage: FC<IDefaultErrorPage> = ({ texts, className }) => {
  const router = useRouter();

  useEffect(() => {
    if (!isNotFoundTestPage(router.asPath)) {
      Logger.error(`Opened not found page with status 404.`);
    }
  }, [router.asPath, texts]);

  return (
    <>
      <Head>
        <title>{texts.pageTitle}</title>
      </Head>
      <div className={`error-page ${className}`}>
        <div className="universal-content-box">
          <div className="static-image-wrapper">
            <Img
              src={PAGE_NOT_FOUND_IMAGE}
              alt="404 Error"
              className="static-image"
            />
          </div>
          <span className="error-page-h">{texts.heading}</span>
          {texts.description && (
            <span className="error-page-description">{texts.description}</span>
          )}

          {/* next/link component crashes the home page after redirect */}
          <a href="/" className="primary-button">
            RETURN TO HOMEPAGE
          </a>
        </div>
      </div>
    </>
  );
};

function isNotFoundTestPage(asPath: string): boolean {
  return asPath.includes("notFoundTestPage");
}

export default DefaultErrorPage;
